
export default {
    state:{
        storageValue: 0, //文件已占用的存储空间大小
        totalStorageValue:0 //总共文件存储大小
    },
    mutations:{
        setStorageValue(state,data){
            state.storageValue = data
        },
        setTotalStorageValue(state,data){
            state.totalStorageValue = data
        }
    },
    action:{
        /**
         * 获取文件已占用的存储空间
         */
        showStorage(context) {
            //TODO
            console.log(context);
            return 100000;
        }
    }
}