import router from "@/router/index";
import store from "@/store";
import {Message} from "element-ui";


router.beforeEach((to, from, next) => {
    //检查当前路由是否登录才可登录
    if(to.matched.some((m)=>m.meta.requireUserAuth)){
        // 调用获取用户登录状态和信息的接口
        store.dispatch('getUserInfo').then(() => {
            if(!store.getters.isLogin){
                Message.warning("未登录,请先登录")
                //没有登录去登录页面
                next({
                    path: '/login',
                })
            }else{
                next();
            }
        })
    }else if(to.matched.some((m)=>m.meta.requireAdminAuth)){
        // 调用获取用户登录状态和信息的接口
        store.dispatch('getUserInfo').then(() => {
            const userStatus = store.getters.userStatus
            if(userStatus !== "3" && userStatus !== "2"){
                Message.warning("您不是管理员")
                //没有登录去登录页面
                next({
                    path: '/main',
                })
            }else{
                next();
            }
        })
    }else {
        next();
        //虽然不需要登录,但是调取用户登录状态和用户信息
        store.dispatch('getUserInfo')
    }
})