import {checkUserLoginInfo} from "@/request/user";
import {Message} from "element-ui";
import Cookies from 'js-cookie';
import globalConfig from "@/config";
export default {
    state:{
        isLogin: false, //用户登录状态
        userInfoObj: {} // 用户信息
    },
    mutations:{
        /**
         * 保存登录状态
         * @param state Vuex的state对象
         * @param data 登录状态
         */
        changeIsLogin(state,data){
            state.isLogin = data;
        },
        /**
         * 保存用户信息
         * @param state
         * @param data 用户信息
         */
        changeUserInfoObj(state,data){
            state.userInfoObj = Object.assign({}, state.userInfoObj, data);
        }
    },
    actions:{
        /**
         * 获取用户信息,返回值为200为正常,605为未登录,
         * 添加功能: 在用户token过期后,仍然存储isLogin为true,此时弹出error框
         * @param context
         * @param state
         * @returns {Promise<unknown>}
         */
        getUserInfo(context){
            return checkUserLoginInfo().then((res)=>{
                const isLogin = Cookies.get(globalConfig.UserIsLogin);
                if(res.code === '200'){
                    //改变登录状态
                    context.commit('changeIsLogin', true);
                    Cookies.set(globalConfig.UserIsLogin, "true");
                    //每次都重置token的时长
                    Cookies.set(globalConfig.UserTokenKeyName, res.data.token);
                    //保存用户信息
                    context.commit('changeUserInfoObj',res.data);
                }else if(res.code === '610'){
                    if(isLogin === "true"){
                        Message.error("用户长时间未操作,请重新登录");
                        context.commit('changeIsLogin',false);
                    }
                    Cookies.set(globalConfig.UserIsLogin, "false");
                    context.commit('changeUserInfoObj', '');
                }
                else{
                    context.commit('changeIsLogin', false);
                    context.commit('changeUserInfoObj', '');
                    Cookies.set(globalConfig.UserIsLogin, "false");
                }
            })
        }
    }
}