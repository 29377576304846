// 文件上传相关接口
import {axiosDelete, get, post, put} from './http'

/**
 * 以登录接口为例
 * export const login = p => get('/user/login', p);
 *
 * login ---------- 接口名称
 * p -------------- 传参，若需要在url中拼接其他信息，传参可以改为(p, other)
 * get ------------ 接口调用的方法，来自 http.js 中封装好的四个axios方法 get/post/put/axiosDelete
 * '/user/login' -- 接口url，若需要在url中拼接其他信息：
 *                  首先需要在传参处改为(p, other1, other2)
 *                  然后将url改为`/user/${other1}/login/${other2}`
 * p -------------- 传递给 get/post/put/axiosDelete 中的查询参数/请求体
 *
 *
 *
 * 除此之外，POST 请求支持请求体格式为 FormData，那么就需要多传递一个参数，true，如下示例：
 * export const example = p => post('/test/example', p, true);
 */


//文件上传
export const updateFile = (p) => post('/file/uploadFile',p);

//普通文件列表文件查询
export const getFileListByType = (p) => get('/file/getFileListByType',p);

//新增文件夹
export const addFileFolder = (p) => post('/file/addFolder',p);

//搜索文件
export const searchFile =(p) => get('/file/searchFile',p);

//将文件放到回收站
export const deleteFile =(p) => axiosDelete("/file/deleteFile",p);

//将文件从回收站彻底删除
export const deleteRecoveryFile =(p) => axiosDelete("/file/deleteRecoveryFile",p);

//从回收站还原文件
export const restoreRecoveryFile =(p) => put("/file/restoreRecoveryFile",p);

//批量将文件放到回收站
export const batchDeleteFile = (p) => axiosDelete("/file/batchDeleteFile", p);

//批量从回收站彻底删除
export const batchDeleteRecoveryFile = (p) => axiosDelete("/file/batchDeleteRecoveryFile", p);

//文件重命名
export const renameFile = (p) => put("/file/renameFile", p);

//获取文件夹树
export const getFolderTree =(p) =>put("/file/getFolderTree",p);

//复制文件
export const copyFile =(p) =>put("/file/copyFile",p);

//移动文件
export const moveFile =(p) => put("/file/moveFile",p);

//下载文件
export const downloadFile =(p) => get("/file/downloadFile",p);

//批量移动文件
export const  batchMoveFile =(p) => put("/file/batchMoveFile",p);

//根据文件Id数组获取文件信息
export const getFileByFileIds =(p) => get("/file/getFileByFileIds",p);

//获取文件树
export const getFileTree = (p) => get("/file/getFileTree", p);