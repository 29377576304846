import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

import '@/router/before.js'
// Buefy
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
//引入全局函数
import globalFunction from '@/lib/globalFunction/index'
// 引入文件操作相关插件
import fileOperationPlugins from '@/plugins/fileOperationPlugins.js'
//登录页背景图的动态实现
import VueParticles from "vue-particles";
//阿里图标库的引入
import '@/assets/styles/iconfont/iconfont.css'
//引入自定义的全局配置
import config from '@/config/index.js'

Vue.use(Buefy)
Vue.use(VueParticles);

Vue.config.productionTip = false

//将全局函数挂载到任何vue文件中
for (let key in globalFunction) {
  Vue.prototype[`$${key}`] = globalFunction[key]
}
//将文件操作函数挂载
for (let key in fileOperationPlugins) {
  Vue.prototype[`$${key}`] = fileOperationPlugins[key]
}

Vue.prototype.$config = config

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
