import Vue from 'vue'
import Vuex from 'vuex'

import screenModule from "@/store/module/screenModule";
import sidebarModule from "@/store/module/sidebarModule";
import fileListModule from "@/store/module/fileListModule";
import fileStorageModule from "@/store/module/fileStorageModule";
import userMess from "@/store/module/userMess";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    //登录状态
    isLogin:(state) => state.userMess.isLogin,
    //用户姓名
    username:(state) => state.userMess.userInfoObj.name,
    //用户ID
    userId:(state) => state.userMess.userInfoObj.id,
    //用户状态
    userStatus:(state) => state.userMess.userInfoObj.status,
    //总共存储空间
    totalStorageValue:(state) => state.fileStorageFunc.totalStorageValue,
    //已用空间
    storageValue:(state) => state.fileStorageFunc.storageValue,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    screenFunc: screenModule,
    sidebarFunc: sidebarModule,
    fileListFunc: fileListModule,
    fileStorageFunc: fileStorageModule,
    userMess,
    // fileInputPathFunc:fileInputPathModule,
  }
})
