<template>

  <div id = "app">
    <div id = "nav">
      <router-view/>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'App',
    mounted() {
      const that = this
      //立即执行函数
      window.addEventListener('resize',function(){
        return(()=>{
          that.$store.commit('changeScreenWidth',document.body.clientWidth)
        })()
      })
    }
  }
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
html {
  height: 100%;
  //overflow: hidden;
  //overflow-y: hidden !important;
  overflow-y: auto !important;
}
body {
  height: 100%;
  font-size: 16px;
  //overflow-x: hidden;
  background-color: #FFF;
  transition: all 0.3s ease;
}
a {
  text-decoration: none;
}
img {
  vertical-align: middle;
}
p {
  line-height: 24px;
}


@media screen and (max-width: 768px) {
  .el-dialog {
    border-radius: 8px !important;
    width: 80% !important;
  }
}




/* 设置全局变量 */
/**
背景色
移入颜色
主题色
主题色发光
文本颜色
 */
:root {
  --body-color: #FFF;
  --shell-color: #e3e8f6;
  --primary-color: rgb(11, 179, 230);
  --primary-color-light: #F6F5FF;
  --toggle-color: #DDD;
  --text-color: #707070;
}

/* 深色主题变量 */
.dark {
  //--body-color: #202224;
  --body-color: #272a37;
  --shell-color: #171717;
  --primary-color: #fdbf7c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
}





</style>