export default {

    state:{
        main_sidebar_isClose:true,
    },

    mutations:{
        /**
         * 这是main页面的侧边栏
         * @param state
         */
        changeSidebarIsClose(state){
            state.main_sidebar_isClose = !state.main_sidebar_isClose;
        },
        changeSidebarAndData(state,data){
            state.main_sidebar_isClose = data;
        },

    }

}