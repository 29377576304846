<template>
  <!-- 右键列表 -->
  <transition name="el-fade-in-linear">
    <!-- 在某个文件上右键 -->
    <ul
        class="right-menu-list"
        id="rightMenuList"
        v-show="visible"
        v-if="selectedFile !== undefined"
        :style="`top: ${rightMenu.top};right: ${rightMenu.right};bottom: ${rightMenu.bottom};left: ${rightMenu.left};`"
    >
      <li
          class="right-menu-item"
          @click="$file.handleFileNameClick(selectedFile, 0, [selectedFile])"
          v-if="seeBtnShow"
      >
        <i class="el-icon-view"></i> 查看
      </li>
      <li
          class="right-menu-item"
          @click="handleDownloadFileBtnClick(selectedFile)"
          v-if="downloadBtnShow"
      >
        <i class="el-icon-download"></i> 下载
      </li>
      <li
          class="right-menu-item"
          @click="handleDeleteFileBtnClick(selectedFile)"
          v-if="deleteBtnShow"
      >
        <i class="el-icon-delete"></i> 删除
      </li>
      <li
          class="right-menu-item"
          @click="handleRestoreFileBtnClick(selectedFile)"
          v-if="restoreBtnShow"
      >
        <i class="el-icon-refresh-left"></i> 还原
      </li>
      <li
          class="right-menu-item"
          @click="handleCopyFileBtnClick(selectedFile)"
          v-if="copyBtnShow"
      >
        <i class="el-icon-copy-document"></i> 复制到
      </li>
      <li
          class="right-menu-item"
          @click="handleMoveFileBtnClick(selectedFile)"
          v-if="moveBtnShow"
      >
        <i class="el-icon-s-promotion"></i> 移动
      </li>
      <li
          class="right-menu-item"
          @click="handleRenameFileBtnClick(selectedFile)"
          v-if="renameBtnShow"
      >
        <i class="el-icon-edit-outline"></i> 重命名
      </li>
      <li
          class="right-menu-item"
          @click="handleShareFileBtnClick(selectedFile)"
          v-if="shareBtnShow"
      >
        <i class="el-icon-share"></i> 分享
      </li>

    </ul>
  </transition>
</template>

<script>

import router from "@/router";

export default {
  name: 'ContextMenu',
  data() {
    return {
      visible: false, //  右键菜单是否显示
      sortedFileList: [], //  排序后的表格数据
      // 右键菜单
      rightMenu: {
        top: 0,
        left: 0,
        bottom: 'auto',
        right: 'auto'
      },
      // 右键解压缩菜单
      unzipMenu: {
        top: 0,
        bottom: 'auto',
        left: '126px',
        right: 'auto'
      },
    }
  },
  computed: {
    // 路由名称
    routeName() {
      return router.currentRoute.name
    },
    // 左侧菜单选中的文件类型
    fileType() {
      return router.currentRoute.query.fileType
          ? Number(router.currentRoute.query.fileType)
          : 0
    },
    // 当前路径
    filePath() {
      return router.currentRoute.query.filePath
    },
    // 查看按钮是否显示
    seeBtnShow() {
      return this.fileType !== 6
    },
    //下载按钮是否显示
    downloadBtnShow(){
      return this.fileType !== 6 && this.selectedFile.file_is_dir === 0
    },
    // 删除按钮是否显示
    deleteBtnShow() {
      return this.fileType !== 8 && !['Share'].includes(this.routeName)
    },
    // 还原按钮是否显示
    restoreBtnShow() {
      return this.fileType === 6 && !['Share'].includes(this.routeName)
    },
    // 复制按钮是否显示
    copyBtnShow() {
      return (
          ![6, 8].includes(this.fileType) && !['Share'].includes(this.routeName)
      )
    },
    // 移动按钮是否显示
    moveBtnShow() {
      return (
          ![6, 8].includes(this.fileType) && !['Share'].includes(this.routeName)
      )
    },
    // 重命名按钮是否显示
    renameBtnShow() {
      return (
          ![6, 8].includes(this.fileType) && !['Share'].includes(this.routeName)
      )
    },
    // 分享按钮是否显示
    shareBtnShow() {
      return (
          // ![6, 8].includes(this.fileType) && !['Share'].includes(this.routeName)
          false
      )
    },

    // 上传文件组件参数
    uploadFileParams() {
      return {
        filePath: this.filePath,
        isDir: 0
      }
    }
  },
  watch: {
    /**
     * 监听右键列表状态
     * @description 右键列表打开时，body 添加点击事件的监听
     */
    visible(newValue) {
      if (newValue === true) {
        document.body.addEventListener('click', this.closeRightMenu)
        this.handleOpenContextMenu()
      } else {
        document.body.removeEventListener('click', this.closeRightMenu)
      }
    }
  },
  methods: {
    /**
     * 打开右键菜单
     */
    handleOpenContextMenu() {
      // 纵坐标设置
      if (
          document.body.clientHeight - this.domEvent.clientY <
          document.querySelectorAll('#rightMenuList > .right-menu-item').length *
          36 +
          10
      ) {
        // 如果到底部的距离小于元素总高度
        this.rightMenu.top = 'auto'
        this.rightMenu.bottom = `${
            document.body.clientHeight - this.domEvent.clientY
        }px`
        this.unzipMenu.top = 'auto'
        this.unzipMenu.bottom = '0px'
      } else {
        this.rightMenu.top = `${this.domEvent.clientY}px`
        this.rightMenu.bottom = 'auto'
        this.unzipMenu.top = '0px'
        this.unzipMenu.bottom = 'auto'
      }
      // 横坐标设置
      if (document.body.clientWidth - this.domEvent.clientX < 138) {
        // 如果到右边的距离小于元素总宽度
        this.rightMenu.left = 'auto'
        this.rightMenu.right = `${
            document.body.clientWidth - this.domEvent.clientX
        }px`
        this.unzipMenu.left = '-200px'
        this.unzipMenu.right = 'auto'
      } else {
        this.rightMenu.left = `${this.domEvent.clientX + 8}px`
        this.rightMenu.right = 'auto'
        this.unzipMenu.left = '126px'
        this.unzipMenu.right = 'auto'
      }
      this.visible = true
    },
    /**
     * 关闭右键列表
     */
    closeRightMenu(event) {
      if (
          !event.target.className.includes('operate-more-') &&
          !event.target.className.includes('unzip-menu-item')
      ) {
        this.visible = false
        if (this.selectedFile !== undefined) {
          // 不是在空白处右键时
          this.callback('cancel')
        }
      }
    },
    /**
     * 复制按钮点击事件
     * @description 向父组件传递当前操作的文件信息，并打开“复制文件对话框”
     * @param {object} fileInfo 文件信息
     */
    handleCopyFileBtnClick(fileInfo) {
      this.visible = false
      this.$openDialog
          .copyFile({
            fileInfo
          })
          .then((res) => {
            this.callback(res)
          })
    },
    /**
     * 移动按钮点击事件
     * @description 向父组件传递当前操作的文件信息，并打开“移动文件对话框”
     * @param {object} fileInfo 文件信息
     */
    handleMoveFileBtnClick(fileInfo) {
      this.visible = false
      this.$openDialog
          .moveFile({
            isBatchMove: false,
            fileInfo
          })
          .then((res) => {
            this.callback(res)
          })
    },
    /**
     * 文件下载
     * @param fileInfo
     */
    handleDownloadFileBtnClick(fileInfo){
      window.open("http://localhost:7091/scloud/api/file/downloadFile?fileId="+fileInfo.id,'_self',"noopener,noreferrer");
      // let url = "http://localhost:7091/scloud/api/file/downloadFile?fileId="+fileInfo.id;
      // fetch(url)
      //     .then(res => res.blob())
      //     .then(blob => {
      //       const a = document.createElement("a");
      //       const objectUrl = window.URL.createObjectURL(blob);
      //       a.download = '文件.zip';
      //       a.href = objectUrl;
      //       a.click();
      //       window.URL.revokeObjectURL(objectUrl);
      //       a.remove();
      //     })
    },
    /**
     * 删除按钮点击事件
     * @description 区分 删除到回收站中 | 在回收站中彻底删除，打开确认对话框
     * @param {object} fileInfo 文件信息
     */
    handleDeleteFileBtnClick(fileInfo) {
      this.visible = false
      this.$openDialog
          .deleteFile({
            isBatchOperation: false,
            fileInfo,
            deleteMode: this.fileType === 6 ? 2 : 1 //  删除类型：1-删除到回收站 2-彻底删除
          })
          .then((res) => {
            this.callback(res)
          })
    },
    /**
     * 还原按钮点击事件
     * @description 调用接口，在回收站中还原文件
     * @param {object} fileInfo 文件信息
     */
    handleRestoreFileBtnClick(fileInfo) {
      this.visible = false
      this.$openDialog
          .restoreFile({
            fileInfo
          })
          .then((res) => {
            this.callback(res)
          })
    },
    /**
     * 文件重命名按钮点击事件
     * @description 打开确认对话框让用户输入新的文件名
     * @param {object} fileInfo 文件信息
     */
    handleRenameFileBtnClick(fileInfo) {
      this.visible = false
      this.$openDialog
          .renameFile({
            fileInfo:fileInfo
          })
          .then((res) => {
            this.callback(res)
          })
    },
    /**
     * 文件分享按钮点击事件
     * @description 打开对话框让用户选择过期时间和提取码
     * @param {object} fileInfo 文件信息
     */
    handleShareFileBtnClick(fileInfo) {
      this.visible = false
      this.$openDialog.shareFile({
        fileInfo: [
          {
            userFileId: fileInfo.userFileId
          }
        ]
      })
    },

    /**
     * 新建文件夹按钮点击事件
     * @description 调用新建文件夹服务，并在弹窗确认回调事件中刷新文件列表
     */
    handleClickAddFolderBtn() {
      this.$openDialog
          .addFolder({
            filePath: router.currentRoute.query.filePath || '/'
          })
          .then((res) => {
            this.callback(res)
          })

    },
    /**
     * 上传文件按钮点击事件
     * @description 通过Bus通信，开启全局上传文件流程
     * @param {boolean} uploadWay 上传方式 0-文件上传 1-文件夹上传 2-粘贴图片或拖拽上传
     */
    handleUploadFileBtnClick(uploadWay) {
      this.$openBox.uploadFile({
        params: this.uploadFileParams,
        uploadWay,
        serviceEl: this.serviceEl,
        callType: true //  callType 调用此服务的方式：1 - 顶部栏，2 - 右键菜单
      })
    }
  }
}
</script>

<style scoped>
.right-menu-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding: 4px 0;
  color: #606266;
  list-style: none;
}

.right-menu-list .right-menu-item,
.right-menu-list .unzip-item {
  padding: 0 16px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}

.right-menu-list .right-menu-item:hover,
.right-menu-list .unzip-item:hover {
  background: #ecf5ff;
  color: #409EFF;
}

.right-menu-list .right-menu-item i {
  margin-right: 8px;
}

.right-menu-list.add .right-menu-item {
  display: flex;
  align-items: center;
}

.right-menu-list.add .right-menu-item img {
  margin-right: 4px;
  height: 20px;
}

.right-menu-list.add .right-menu-item i {
  margin-right: 4px;
  font-size: 18px;
}

.right-menu-list .unzip-menu-item {
  position: relative;
}

.right-menu-list .unzip-menu-item:hover .unzip-list {
  display: block;
}

.right-menu-list .unzip-menu-item .unzip-list {
  position: absolute;
  display: none;
  list-style: none;
}

.right-menu-list .unzip-menu-item .unzip-list .unzip-item {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.right-menu-list,
.unzip-list {
  background: #fff;
  border: 1px solid #EBEEF5;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding: 8px 0;
  color: #606266;
  font-size: 14px;
}

.right-menu-list .el-divider {
  margin: 2px 0;
}

</style>
