export default {
    state:{
        //被选中的文件列表
        selectedFiles:[]
    },
    mutations:{
        /**
         * 设置批量操作下被选中的文件列表
         * @param state Vuex 的state对象
         * @param data 批量操作模式下,被选中的文件列表
         */
        changeSelectedFiles(state,data){
            state.selectedFiles = data;
        }
    }

}