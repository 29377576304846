export default {

    state:{
        screenWidth:document.body.clientWidth,
        screenSizeIsBig:document.body.clientWidth > 576
    },

    mutations:{
        /**
         * 改变屏幕宽度
         * @param stata VuexDe stata对象
         * @param data 屏幕宽度
         */
        changeScreenWidth(stata,data){
            stata.screenWidth = data;
            stata.screenSizeIsBig = data > 576
        }
    }
}