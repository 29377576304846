import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect : '/home'
  },
  {
    path: '/login',
    name: 'loginPage',
    component: () => import('../views/login/LoginView.vue')
  },
  {
    path:'/home',
    name: 'HomeFrame',
    redirect:'/home/main',
    component: () => import('../frame/HomeFrame.vue'),
    children: [
      {
        path:'main',
        name:'前台首页',
        component:()=> import('../views/front/FrontMain.vue')
      },
      {
        path:'community',
        name:'前台社区',
        redirect:'/home/community/main',
        component:()=>import('../views/front/FrontCommunity.vue'),
        children:[
          {
            path:"main",
            component:()=>import('@/views/front/component/FrontCommunityMain.vue')
          },
          {
            path: 'article/:id',
            name:'前台文章详情页',
            component: () => import('@/views/front/component/FrontArticleDetails.vue')
          },
        ]
      },
      {
        path:'notice',
        name:'前台公告',
        redirect: 'notice/table',
        component:()=>import('../views/front/FrontNotice.vue'),
        children: [
          {
            path:'table',
            component:()=>import('../views/main/child/NoticePageView.vue'),

          },
          {
            path:'details',
            name:'前台公告详情',
            component:()=>import('@/views/back/child/NoticeDetails.vue')
          }
        ]
      },
      {
        path:'about',
        name:'关于',
        component:()=>import('../views/front/FrontAbout.vue')
      }
    ]
  },
  {
    path: '/main',
    redirect: '/main/front',
    component: () => import('../frame/MainFrontFrame.vue'),
    meta:{
      requireUserAuth:true //当前页面需要登录才能进入
    },
    children:[
      {
        path:'front',
        name:'主页首页',
        component:()=>import('../views/main/FrontPageView.vue'),
        meta:{
          requireUserAuth:true //当前页面需要登录才能进入
        },
      },
      {
        path:'notice',
        redirect: 'notice/table',
        name:'主页公告',
        component: () => import('../views/main/NoticeFrame.vue'),
        children: [
          {
            path:'table',
            component:()=>import('../views/main/child/NoticePageView.vue'),

          },
          {
            path:'details',
            name:'主页公告详情',
            component:()=>import('@/views/back/child/NoticeDetails.vue')
          }
        ]
      },

      {
        path:'community',
        name:'主页社区',
        component:()=>import('../views/main/CommunityPageView.vue'),
      },
      {
        path: 'article/:id',
        name: '文章详情页',
        component: () => import('@/views/home/community/articleDetails.vue')
      },
      {
        path:'tag/:name',
        name:'标签查询页',
        component:()=>import('@/views/home/community/view/search/TagComponent.vue')
      },
      {
        name: '用户主页',
        path: 'member/:userId/home',
        component: () => import('@/views/home/community/view/search/Profile.vue'),
        meta: { title: '用户主页' }
      },
      {
        path:'group',
        name:'主页群组',
        component:()=>import('../views/main/GroupPageView.vue'),
      },
      {
        path:'person',
        name:'主页个人信息',
        redirect: 'person/overview',
        component:()=>import('../views/main/PersonalPageView.vue'),
        children:[
          {
            path:'overview',
            name:'个人信息总览',
            component: () => import('../views/home/person/OverviewComponent.vue'),
          },
          {
            path:'account',
            name:'个人账户管理',
            component: () => import('../views/home/person/AccountComponent.vue'),
          },
          {
            path:'article',
            name:'个人发布文章管理',
            component:()=>import('../views/home/person/ArticleComponent.vue'),
          },
          {
            path:'setting',
            name:'个人信息设置',
            component:()=>import('../views/home/person/SettingComponent.vue')
          }

        ]
      },

      {
        path:'cloud',
        name:'个人云盘',
        component:()=>import('../views/main/CloudPageView.vue'),
      },
      {
        path: 'create',
        name:'新建文章',
        component:()=>import('../views/home/community/view/post/Create.vue')
      },
      {
        path:'edit/:id',
        name:'编辑文章',
        component: () => import('@/views/home/community/view/post/Edit.vue'),
      }
    ]
  },
  {
    path:'/back',
    redirect: '/back/user',
    component:()=>import('../frame/MainBackFrame.vue'),
    meta:{
      requireAdminAuth:true //当前页面需要登录才能进入
    },
    children: [
      {
       path:'backHome',
       name:'后台首页' ,
        component: () => import('@/views/back/backHome.vue'),
      },
      {
        path:'user',
        name:'后台用户管理',
        component: () => import('@/views/back/BackUser.vue'),
      },
      {
        path:'admin',
        name: '后台管理员管理',
        component:()=>import('@/views/back/BackAdmin.vue')
      },
      {
        path:'normalArticle',
        name:'后台正常文章管理',
        component:()=>import('@/views/back/BackNormalArticle.vue')
      },
      {
        path:'blackArticle',
        name:'黑名单文章',
        component:()=>import('@/views/back/BackBlackArticle.vue')
      },
      {
        path:'publishNotice',
        name:'发布公告',
        component:()=>import('@/views/back/BackPublishNotice.vue')
      },
      {
        path:'historyNotice',
        name:'历史公告',
        redirect:'historyNotice/table',
        component:()=>import('@/views/back/BackHistoryNotice.vue'),
        children:[
          {
            path:'table',
            name:'后台数据',
            component: () => import('@/views/back/child/NoticeTable.vue'),
          },
          {
            path:'details',
            name:'公告详情',
            component:()=>import('@/views/back/child/NoticeDetails.vue')
          }
        ]
      },
      {
        path:'tag',
        name:'标签管理',
        component:()=>import('@/views/back/BackTag.vue')
      },
      {
        path:'userInfo',
        name:'用户信息',
        component:()=>import('@/views/back/BackUserInfo.vue')
      }


    ]

  },
  {
    path: '/test',
    component: () => import('../views/TestAPI.vue'),
  },
  {
    path: '/test2',
    component: () => import('../views/TestAPI2.vue'),
  },

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//解决 Navigation cancelled from “/xxx“ to “/xxx“ with a new navigation.
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
