import Cookies from 'js-cookie'
import config from "@/config";

const commonFunction={

    /**
     * 设置 Cookies
     * @param {string} name 名称
     * @param {string} value 值
     * @param {object} others 域名、路径、有效期等，封装到对象中
     */
    setCookies(name, value, others = null) {
        Cookies.set(name,value,{domain:config.domain,...others})
    },
    /**
     * 获取 Cookies
     * @param {string} name 名称
     * @param {object} others 域名、路径等，封装到对象中
     * @returns {string} Cookies 值
     */
    getCookies(name, others = null) {
        return Cookies.get(name, { domain: config.domain, ...others })
    },
    /**
     * 移除 Cookies
     * @param {string} name 名称
     * @param {object} others 域名、路径等，封装到对象中
     */
    removeCookies(name, others = null) {
        Cookies.remove(name, { domain: config.domain, ...others })
    },

    setGrade(memberPoints){
        if(!memberPoints || memberPoints === 0){
            return "Lv1";
        }else if(memberPoints >= 10 && memberPoints < 100){
            return "Lv2";
        }else if(memberPoints >= 100 && memberPoints < 300){
            return "Lv3";
        }else if(memberPoints >= 300 && memberPoints < 1000){
            return "Lv4";
        }else if(memberPoints >= 1000 && memberPoints < 3000){
            return  "Lv5";
        }else if(memberPoints >= 3000 && memberPoints < 5000){
            return "Lv6";
        }else if(memberPoints >= 5000 && memberPoints < 10000){
            return "Lv7";
        }else {
            return "Lv10086";
        }
    }

}
export default commonFunction