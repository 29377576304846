
import {fileImgMap, unknownImg} from "@/lib/map";
import router from "@/router";

//全局函数 - 文件相关
const fileFunction={
    /**
     * 根据文件扩展名设置文件图片
     * @param file 文件信息
     */
    setFileImg(file){
        if (file.file_is_dir === 1) {
            //  文件夹
            return fileImgMap.get('dir')
        } else if (
            ['jpg', 'png', 'jpeg', 'gif'].includes(
                file.file_type.toLowerCase()
            )
        ) {
            // 图片、视频类型，直接显示缩略图
            return file.file_url_true;
        } else if (fileImgMap.has(file.file_type.toLowerCase())) {
            // 可以识别文件类型的文件
            return fileImgMap.get(file.file_type.toLowerCase())
        } else {
            // 无法识别文件类型的文件
            return unknownImg
        }
    },
    /**
     * 获取当前文件是不是图片
     * @param file
     * @returns {number}
     */
    getFileImgType(file){
        if(file.file_is_dir === 1){
            return 0;
        }else if (
            ['jpg', 'png', 'jpeg', 'gif'].includes(
                file.file_type.toLowerCase()
            )
        ){
            return 1;
        }else {
            return 0
        }
    },
    /**
     * 文件预览
     * 若当前点击的是文件夹,则进入文件夹内部,若是文件,则进行文件预览操作
     * @param row 文件信息
     * @param currentIndex 当前文件索引
     * @param fileList 文件列表
     */
    handleFileNameClick(row, currentIndex, fileList = []){
        console.log(row);
        console.log(currentIndex);
        console.log(fileList);
        if(row.file_is_dir === 1){
            router.push({
                query: {
                    filePath: (row.file_url_false === "/" ? "" : row.file_url_false) + "/" + row.file_name,
                    fileType: 0}
            })
         }else{
            window.open(row.file_url_true);
        }
    },

    /**
     * 格式化文件大小,将这个带上单位
     * @param {number} size 文件大小
     * @param {boolean} isInteger 是否只显示整数
     * @return {string} 带单位的文件大小
     */
    calculateFileSize(size,isInteger =false){
        const B = 1024
        const KB = Math.pow(1024, 2)
        const MB = Math.pow(1024, 3)
        const GB = Math.pow(1024, 4)
        if (isInteger) {
            // 截取为整数
            if (size < B) {
                return `${size}B`
            } else if (size < KB) {
                return `${(size / B).toFixed(0)}KB`
            } else if (size < MB) {
                return `${(size / KB).toFixed(0)}MB`
            } else if (size < GB) {
                return `${(size / MB).toFixed(0)}GB`
            } else {
                return `${(size / GB).toFixed(0)}TB`
            }
        } else {
            // 保留小数位
            if (size < B) {
                return `${size}B`
            } else if (size < KB) {
                return `${(size / B).toFixed(0)}KB`
            } else if (size < MB) {
                return `${(size / KB).toFixed(1)}MB`
            } else if (size < GB) {
                return `${(size / MB).toFixed(2)}GB`
            } else {
                return `${(size / GB).toFixed(3)}TB`
            }
        }
    },
    /**
     * 文件类型转换
     * @param {object} file
     */
    getFileType(file){
        return file.file_is_dir === 1
            ? '文件夹'
            : file.file_type
                ? file.file_type
                :'文件'
    },
    /**
     * 获取文件分享过期状态
     * @param {string} time 日期
     * @returns {boolean} 是否过期
     */
    getFileShareStatus(time) {
        if (new Date(time).getTime() > new Date().getTime()) {
            return false
        } else {
            return true
        }
    }

}

export default fileFunction