/**
 * 存放项目域名管理
 */
const config={
    domain:
        process.env.NODE_ENV ==='production'
            ? location.host.indexOf('.yhmuxi.cn') !== -1
                ? '.yhmuxi.cn'
                : ''
            :location.hostname,

    /**
     * 以下是cookie中存放的数据
     */
    /**
     * 请求后台接口URL代理的上下文
     */
    baseContext:'/scloud/api',
    /**
     * 存放UserToken时的命名方式
     */
    UserTokenKeyName: 'UserToken',
    /**
     * 存放EmailToken时的命名方式
     */
    EmailTokenKeyName: 'EmailToken',
    /**
     * 存放用户是否登录
     */
    UserIsLogin:'UserIsLogin'
}

export default config