// 用户信息相关接口
import {get , post } from './http'

/**
 * 以登录接口为例
 * export const login = p => get('/user/login', p);
 *
 * login ---------- 接口名称
 * p -------------- 传参，若需要在url中拼接其他信息，传参可以改为(p, other)
 * get ------------ 接口调用的方法，来自 http.js 中封装好的四个axios方法 get/post/put/axiosDelete
 * '/user/login' -- 接口url，若需要在url中拼接其他信息：
 *                  首先需要在传参处改为(p, other1, other2)
 *                  然后将url改为`/user/${other1}/login/${other2}`
 * p -------------- 传递给 get/post/put/axiosDelete 中的查询参数/请求体
 *
 *
 *
 * 除此之外，POST 请求支持请求体格式为 FormData，那么就需要多传递一个参数，true，如下示例：
 * export const example = p => post('/test/example', p, true);
 */

//用户登录
export const loginByNameOrEmail = (p) => post('/login/loginByNameOrEmail',p);

//用户注册
export const register = (p)=> post('/login/register',p);

//忘记密码
export const forgetPassword = (p)=> post('/login/forgetPassword',p);

//发送验证码
export const sendCode = (p) => get("/login/sendCode", p);

//获取登录状态和用户信息 , 检查用户token的有效性
export const checkUserLoginInfo = (p) => get('/login/checkUserLoginInfo',p);

//检查邮箱token有效性
export const checkEmailCode = (p) => get('/login/checkEmailCode',p);

//个人中心修改主页面
export const changeUserInfo = (p) => post('/login/changeUserInfo', p);

//个人中心修改密码
export const changeUserPassword = (p) => get('/login/changeUserPassword',p);

//注销登录
export const userLogout = (p) => get('/login/logout',p);

//通过用户Id获取用户信息
export const getUserInfo = (p) => get('/login/getUserInfo',p);